import Link from 'gatsby-link'
import React from "react"
import rewriteSlug from '../utils/rewriteSlug'
import { StaticImage } from 'gatsby-plugin-image';

const Nav = ({ settings, lang }) => (
  <header className="w-full bg-gray-700 text-white">
    <nav className="" role="navigation">
      <div className="container mx-auto p-2 flex flex-wrap items-center md:flex-no-wrap">
        <div className="mr-4 md:mr-8">
          <Link to="/">
            <StaticImage
              src={"https://a.storyblok.com/f/150446/201x88/be3f9c5638/grumeti-media-small-nobg.png"}
              alt="grumeti media logo"
              width={101}
              height={44}
            />
          </Link>
        </div>
        <div className="text-primary">
          
        </div>
        <div className="ml-auto sm:hidden">
          <button
            className="flex items-center px-3 py-2 border rounded"
            type="button"
          >
            <svg
              className="h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div className="w-full md:w-auto md:flex-grow md:flex md:items-center">
          <ul className="flex flex-col mt-4 -mx-4 pt-4 border-t md:flex-row md:items-center md:mx-0 md:mt-0 md:pt-0 md:mr-4 md:ml-auto lg:mr-8 md:border-0">
            {settings &&
              settings.content.main_navi.map((navitem, index) => (
                <li key={index}>
                  <Link to={`/${rewriteSlug(navitem.link.cached_url)}`} prefetch="true" className="block px-4 py-1 md:p-2 lg:px-8">
                    {navitem.name}
                  </Link>
                </li>
              ))}
          </ul>
          { /*
          <ul className="flex flex-col mt-4 -mx-4 pt-4 border-t md:flex-row md:items-center md:mx-0 md:mt-0 md:pt-0 md:border-0">
            <li>
              <Link to="/"
                className={`block px-4 py-1 md:p-2 rounded-lg lg:px-4 ${lang === 'en' ? "bg-primary text-white" : ""}`}
              >
                EN
              </Link>
            </li>
            <li>
              <Link to="/de"
                className={`block px-4 py-1 md:p-2 rounded-lg lg:px-4 ${lang === 'de' ? "bg-primary text-white" : ""}`}
              >
                SW
              </Link>
            </li>
          </ul>
              */}
        </div>
      </div>
    </nav>
  </header>
);

export default Nav;
